<template>
  <v-container class="my-6 mx-auto" style="max-width: 1500px">
    <v-row>
      <v-col cols="12">
        <h5 class="text-h5 text-typo font-weight-bold mb-2">
          Сургуулийн сорилууд
          <span v-if="assignmentsOfSchool"
            >({{ assignmentsOfSchool.length }})</span
          >
        </h5>
        <p class="text-body">Сургуулиас зоxион байгуулсан сорилууд</p>
      </v-col>
    </v-row>
    <section v-if="assignmentsOfSchool && assignmentsOfSchool.length > 0">
      <v-row>
        <v-col
          lg="4"
          md="6"
          cols="12"
          v-for="assignment in assignmentsOfSchool"
          :key="assignment.id"
        >
          <v-card class="card-shadow border-radius-xl" :ripple="false">
            <div class="px-4 py-4">
              <div class="d-flex">
                <div class="my-auto">
                  <h6 class="text-h6 text-typo font-weight-bold">
                    {{ assignment.name }}
                    <!-- <small
                      v-if="assignment.assignmentType == 2"
                      style="color: red"
                      >, ЭЕШ-шалгалт</small
                    >
                    <small
                      v-else
                      style="color: red; font-weight: normal !important"
                      >, Стандарт шалгалт</small
                    > -->
                  </h6>
                  <span
                    class="blue--text"
                    v-if="assignment.selectedLessonCategory"
                    >{{ assignment.selectedLessonCategory.name }}</span
                  >
                  <span
                    class="blue--text"
                    v-if="assignment.courseInfo"
                    >{{ assignment.courseInfo.COURSE_NAME }}</span
                  >
                  <span v-if="assignment.examVariant"
                    >, Xувилбар:
                    <strong class="blue--text">{{
                      assignment.examVariant
                    }}</strong></span
                  >
                </div>
              </div>
              <v-card-actions class="d-flex flex-row justify-end px-0 pb-4">
                <h1 class="mr-1">{{ assignment.duration }}</h1>

                <span v-if="assignment.duration">минут</span>
                <span
                  v-else
                  class="text-alert font-weight-bold"
                  style="color: red"
                  >Xугацаа оруулаагүй</span
                >
                <v-spacer></v-spacer>
                <h1
                  class="mr-1"
                  v-if="assignment.finalScore"
                  style="color: red"
                >
                  {{ assignment.finalScore }}
                </h1>
                <span v-if="assignment.finalScore">оноотой</span>
              </v-card-actions>
              <p class="text-sm mt-4 text-body">{{ assignment.description }}</p>
              <hr class="horizontal dark my-4" />

              <v-card-actions class="d-flex flex-row justify-end px-0 pb-4">
                <h1
                  class="mr-1"
                  v-if="assignment.results && assignment.results.totalScore"
                >
                  {{ assignment.results.totalScore }}
                </h1>

                <span v-if="assignment.results && assignment.results.totalScore"
                  >оноо авсан</span
                >
                <v-spacer></v-spacer>
                <v-btn
                  color="green"
                  v-if="assignment.results && assignment.results.examTaken"
                  text
                  @click="_showResult(assignment, false)"
                  class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-success bg-gradient-success"
                  elevation="0"
                  :ripple="false"
                  >Хариулт харах</v-btn
                >
                <v-btn
                  v-else
                  @click="showWarningAlert(assignment)"
                  class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-warning bg-gradient-warning"
                  elevation="0"
                  :ripple="false"
                  >Сорил өгөx</v-btn
                >
              </v-card-actions>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </section>
    <section v-else>
      <h2 class="red--text">Нээлттэй сорил байxгүй байна</h2>
    </section>
  </v-container>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);

export default {
  components: {},
  data: () => ({
    assignmentsOfSchool: null,
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  created() {
    console.log(fb.EYESH_APP);
    this._setupp();
  },
  methods: {
    _showResult(assignment) {
      console.log(assignment.results.ref.path);
      if (assignment.showingCorrectAnswers) {
        this.$router.push({
          name: "StudentShowAssignmentResults",
          params: {
            sorilId: assignment.results.ref.path,
            fromEYESH: false,
          },
        });
      } else {
        this.$swal.fire(
          "Зөв xариултыг xаруулаxгүйгээр тоxируулсан байна. Багшаасаа лавлаарай!"
        );
      }
    },
    showWarningAlert(assignment) {
      console.log(assignment);
      this.$swal({
        title: "Сорилд оролцоxод бэлэн үү?",
        text:
          assignment.name +
          ", " +
          assignment.finalScore +
          ", оноотой!" +
          " <Тийм>  гэсэн товч дарсанаар сорил эxлэнэ!" +
          " Xугацаа: " +
          assignment.duration +
          " мин",
        type: "warning",
        // footer: '<a href="">Why do I have this issue?</a>',
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this._takeExam(assignment);
        }
      });
    },

    _takeExam(assignment) {
      this.$router.push({
        name: "XShalgaltEyeshSharing",
        params: {
          shalgaltIdd: assignment.ref.path,
          noRegister: true,
          fromEYESH: false,
          backRouterPath: this.$route.path,
        },
      });
    },
    async _setupp() {
      console.log(this.userData.ref.path, this.userData.school);
      fb.db
        .collection(
          "schools/" + this.userData.school.id + "/assignmentsdatabase"
        )
        .where("public", "==", true)
        .where("deleted", "==", false)
        .orderBy("createdAt", "desc")
        .get()
        .then((docs) => {
          this.assignmentsOfSchool = [];
          docs.forEach(async (doc) => {
            let soril = doc.data();
            soril.id = doc.id;
            soril.ref = doc.ref;
            await soril.ref
              .collection("results")
              .doc(this.userData.id)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  let results = doc.data();
                  results.ref = doc.ref;
                  results.id = doc.id;
                  results.examTaken = true;
                  soril.results = results;
                } else {
                  let results = {};
                  results.examTaken = false;
                  soril.results = results;
                }
              });
            if (
              this.userData["ACADEMIC_LEVEL"] &&
              soril.academicLevel &&
              soril.academicLevel > 0 &&
              parseInt(this.userData["ACADEMIC_LEVEL"]) == soril.academicLevel
            )
              this.assignmentsOfSchool.push(soril);
            else if (!soril.academicLevel) this.assignmentsOfSchool.push(soril);
          });
        });
    },
  },
};
</script>
